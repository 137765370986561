* {
  box-sizing: border-box;
}

@font-face {
  font-family: metropolic-bold;
  src: url("../../../../asset/metropolis/Metropolis-Black.otf");
}

@font-face {
  font-family: metropolic-medium;
  src: url("../../../../asset/metropolis/Metropolis-Medium.otf");
}

@font-face {
  font-family: metropolic-regular1;
  src: url("../../../../asset/metropolis/Metropolis-Regular.otf");
}

.infara-heading {
  text-align: center;
}

.infara-heading h1 {
  font-family: metropolic-regular1;
  font-size: 30px;

  color: #0f3851;
}

.model-img {
  margin-left: 0 !important;
}

.infara-heading span {
  font-family: metropolic-bold;
  color: #f7ad00;
}

.preview {
  display: inline-flex;
  /* border: 1px solid black; */
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  padding: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.preview p {
  font-family: metropolic-regular1;
  font-size: 15px;
  padding: 0px 10px;
  margin: 0 !important;
  margin-left: 5px;
  text-transform: capitalize;
}


@media only screen and (max-width: 425px){
  .mod-img{
    display: flex;
    justify-content: center;
  }
}

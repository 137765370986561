* {
  box-sizing: border-box;
}

@font-face {
  font-family: metropolic-nbold;
  src: url("../../../../asset/metropolis/Metropolis-Bold.otf");
}

@font-face {
  font-family: metropolic-medium;
  src: url("../../../../asset/metropolis/Metropolis-Medium.otf");
}

/* .ourstrength{
      
      display:flex;
      justify-content: space-between;
      align-items: center;
  } */

.circledesign1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circledesign2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circledesign3 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circledesign4 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.circle-container {
  border: 2px #adadad dotted;
  display: inline-block;
  padding: 18px;
  border-radius: 50%;
}

.circle-content {
  margin-left: 15px;
  margin-top: 10px;
  width: 60%;
}

.circle-content h5 {
  font-family: metropolic-nbold;
  font-size: 18px;
  color: #333333;
}

* {
  box-sizing: border-box;
}

@font-face {
  font-family: metropolic-bold;
  src: url("../../../asset/metropolis/Metropolis-Black.otf");
}

@font-face {
  font-family: metropolic-bold2;
  src: url("../../../asset/metropolis/Metropolis-ExtraBold.otf");
}

@font-face {
  font-family: metropolic-medium;
  src: url("../../../asset/metropolis/Metropolis-Medium.otf");
}

@font-face {
  font-family: metropolic-regular3;
  src: url("../../../asset/metropolis/Metropolis-Regular.otf");
}

@font-face {
  font-family: metropolic-semi-bold;
  src: url("../../../asset/metropolis/Metropolis-SemiBold.otf");
}

.services-bg {
  background-image: url("../../../asset/services-bg.png");
  width: 100%;
  height: 40vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.servicespg-heading {
  margin-top: auto;
  margin-bottom: 20px;
}

.servicespg-heading p {
  font-family: metropolic-medium;
  margin-bottom: 0 !important;
}

.servicespg-heading h1 {
  font-family: metropolic-bold;
  color: #1e4f6d;
}

.service1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ono-img {
  display: inline-block;
  background-color: #f7ad00;
  padding: 23px;
  border-radius: 100px;
  margin-left: 20px;
  margin-right: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}



.ono-content h5 {
  font-family: metropolic-bold;
  text-align: right;
  color: #1e4f6d;
}

.ono-content p {
  font-family: metropolic-regular3;
  text-align: justify;
  font-size: 14px;
}

.vac-img {
  display: inline-block;
  background-color: #1e4f6d;
  padding: 18px;
  border-radius: 100px;
  margin-left: 20px;
  margin-right: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.vac-content h5 {
  font-family: metropolic-bold;
  text-align: right;
  color: #f7ad00;
}

.vac-content p {
  font-family: metropolic-regular3;
  text-align: justify;
  font-size: 14px;
}

.med-img {
  display: inline-block;
  background-color: #1e4f6d;
  padding: 18px;
  border-radius: 100px;
  margin-left: 20px;
  margin-right: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.med-content h5 {
  font-family: metropolic-bold;
  text-align: left;
  color: #f7ad00;
}

.med-content p {
  font-family: metropolic-regular3;
  text-align: justify;
  font-size: 14px;
}

.cos-img {
  display: inline-block;
  background-color: #f7ad00;
  padding: 18px;
  border-radius: 100px;
  margin-left: 20px;
  margin-right: 30px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.cos-content h5 {
  font-family: metropolic-bold;
  text-align: left;
  color: #1e4f6d;
}

.cos-content p {
  font-family: metropolic-regular3;
  text-align: justify;
  font-size: 14px;
}

.categoty_list{
  /* display: flex; */
  /* justify-content: left; */
  /* align-items: center; */
  /* border: 1px solid rgb(218, 218, 218); */
  padding: 28px 20px 10px 20px;
  border-radius: 10px;
  /* background-color: #1e4f6d; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

/* max-width: 250px;
max-height: 250px; */


}

.category_image {
  display: inline-block;
  justify-content: center;
  align-items: flex-end;
  /* padding: 18px; */
  padding-top: 20px;
  text-align: center;
  /* border-bottom: 1px solid rgb(228, 228, 228); */
}

.categoty_list img {
max-width: 90%;
  background-color: #f7ad00;
/* filter: saturate(10); */
/* width: 100px;
height: 100px; */
  border-radius: 8px;
  padding: 10px;
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
}



.categoty_list h5 {
  font-family: metropolic-bold2;
  font-size: 14px;
  margin-top: 14px;
  /* margin-left: 15px; */
  color: #4d4d4d;
  text-align: center;
  line-height: 23px;
  word-break: break-all;

}

.category_heading{
  text-align: center;
}

.category_heading h1 {
  font-family:metropolic-bold ;
  color: #1e4f6d;
  font-size: 35px;
}

.category_heading span {
  color: #f7ad00;
}


.service_gear2{
  background-color: rgb(255, 255, 255);
  width: 240px;
  height: 240px;
  padding: 20px;
  border-radius: 500px;
  margin: 40px auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.service_gear2 .icon{

  font-size: 130px;
  color: #d6d6d6;
}


@media screen and (max-width: 1024px) {
  .ono-img {
    display: inline-block;
    background-color: #f7ad00;
    padding: 18px;
    border-radius: 100px;
    margin-left: 20px;
    margin-right: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .ono-content h5 {
    font-family: metropolic-bold;
    text-align: right;
    color: #1e4f6d;
    font-size: 16px;
  }

  .ono-content p {
    font-family: metropolic-regular3;
    text-align: justify;
    font-size: 11px;
  }

  .vac-img {
    display: inline-block;
    background-color: #1e4f6d;
    padding: 18px;
    border-radius: 100px;
    margin-left: 20px;
    margin-right: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .vac-content h5 {
    font-family: metropolic-bold;
    text-align: right;
    color: #f7ad00;
    font-size: 16px;
  }

  .vac-content p {
    font-family: metropolic-regular3;
    text-align: justify;
    font-size: 11px;
  }

  .med-img {
    display: inline-block;
    background-color: #1e4f6d;
    padding: 18px;
    border-radius: 100px;
    margin-left: 20px;
    margin-right: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .med-content h5 {
    font-family: metropolic-bold;
    text-align: left;
    color: #f7ad00;
    font-size: 16px;
  }

  .med-content p {
    font-family: metropolic-regular3;
    text-align: justify;
    font-size: 11px;
  }

  .cos-img {
    display: inline-block;
    background-color: #f7ad00;
    padding: 18px;
    border-radius: 100px;
    margin-left: 20px;
    margin-right: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .cos-content h5 {
    font-family: metropolic-bold;
    text-align: left;
    color: #1e4f6d;
    font-size: 16px;
  }

  .cos-content p {
    font-family: metropolic-regular3;
    text-align: justify;
    font-size: 11px;
  }

  .service_gear2{
    background-color: rgb(255, 255, 255);
    width: 220px;
    height: 220px;
    padding: 20px;
    border-radius: 500px;
    margin: 40px auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .service1 {
    display: inline-block;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .service-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ono-img {
    display: inline-block;
    background-color: #f7ad00;
    padding: 18px;
    border-radius: 100px;
    margin-left: 20px;
    margin-right: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .ono-content h5 {
    font-family: metropolic-bold;
    text-align: center;
    color: #1e4f6d;
    font-size: 19px;
  }

  .ono-content p {
    font-family: metropolic-regular3;
    text-align: center;
    font-size: 14px;
  }

  .vac-img {
    display: inline-block;
    background-color: #1e4f6d;
    padding: 18px;
    border-radius: 100px;
    margin-left: 20px;
    margin-right: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .vac-content h5 {
    font-family: metropolic-bold;
    text-align: center;
    color: #f7ad00;
    font-size: 19px;
  }

  .vac-content p {
    font-family: metropolic-regular3;
    text-align: center;
    font-size: 14px;
  }

  .med-img {
    display: inline-block;
    background-color: #1e4f6d;
    padding: 18px;
    border-radius: 100px;
    margin-left: 20px;
    margin-right: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 15px;
  }

  .med-content h5 {
    font-family: metropolic-bold;
    text-align: center;
    color: #f7ad00;
    font-size: 19px;
  }

  .med-content p {
    font-family: metropolic-regular3;
    text-align: center;
    font-size: 14px;
  }

  .cos-img {
    display: inline-block;
    background-color: #f7ad00;
    padding: 18px;
    border-radius: 100px;
    margin-left: 20px;
    margin-right: 30px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-bottom: 15px;
  }

  .cos-content h5 {
    font-family: metropolic-bold;
    text-align: center;
    color: #1e4f6d;
    font-size: 19px;
  }

  .cos-content p {
    font-family: metropolic-regular3;
    text-align: center;
    font-size: 14px;
  }

  /* .service-gear img {
    margin: 50px auto;
  } */
  .service_gear2{
    background-color: rgb(255, 255, 255);
    width: 240px;
    height: 240px;
    padding: 20px;
    border-radius: 500px;
    margin: 40px auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 425px) {
  .service-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  /* .service-gear img {
    margin: 50px auto;
  } */
  .service_gear2{
    background-color: rgb(255, 255, 255);
    width: 240px;
    height: 240px;
    padding: 20px;
    border-radius: 500px;
    margin: 40px auto;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .categoty_list{
  padding: 20px 15px 10px 15px;

  }
}

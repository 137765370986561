* {
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: metropolic-bold;
    src: url("../../../../asset/metropolis/Metropolis-Black.otf");
  }
  
  @font-face {
    font-family: metropolic-medium;
    src: url("../../../../asset/metropolis/Metropolis-Medium.otf");
  }
  
  @font-face {
    font-family: metropolic-regular1;
    src: url("../../../../asset/metropolis/Metropolis-Regular.otf");
  }


  .ourteam-image{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin-top: 30px;
  }

 

  .ourteam-image img {
      width: 80%;
      object-fit: cover;
  }

  .ourteam-heading h1{
      font-family: metropolic-regular1;
      font-size: 30px;
      color: #0F3851;

  }

  .ourteam-heading span{
      font-family:metropolic-bold ;
      color: #F7AD00;
  }
  .ourteam-names{
      text-align: center;
      
  }

  .ourteam-names h3{
      font-family:metropolic-bold ;
      margin-bottom: 0 !important;
      margin-top: 18px;
      font-size: 19px;
      color: #0F3851;
  }

  .h-img-content{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    border-radius: 10px;
 
}

.h-img-content p{
  background-color: #1E4F6D;
  width: 0;
  transition: all .3s;
  overflow: hidden;
  height: 0;
display: flex;
justify-content: center;
align-items: center;
color: #fff;
border-radius: 10px;
opacity:0 ;
font-family: metropolic-medium;
padding: 10px;
  
}

.h-img-content:hover p{
  background-color: #1E4F6D;
  transition: all .3s;
  width: 100%;
  height: 100%;
  text-align: center;
  opacity: 0.9;



}



* {
  box-sizing: border-box;
}
/* 
arif is a good designer and a wellversed eveloper

*/
@font-face {
  font-family: metropolic-wbold;
  src: url("../../asset/metropolis/Metropolis-Bold.otf");
}

@font-face {
  font-family: metropolic-medium;
  src: url("../../asset/metropolis/Metropolis-Medium.otf");
}

@font-face {
    font-family: metropolic-regular;
    src: url("../../asset/metropolis/Metropolis-Regular.otf");
  }

  

.bg-color {
 
  background-color: #0f3851;
  margin-top: 100px;

  padding-bottom: 30px;
}

.footer h5 {
  font-family: metropolic-wbold;
  color: #f7ad00;
  font-size: 18px;
}

.footer-colum1 img {
  margin-top: 15px;
}

.footer-colum1 p {
  font-family: metropolic-regular;
  font-size: 15px;
  color: white;
  margin-top: 15px;
}

.social-icons .icons {
  font-size: 35px;
  color: white;
  margin-right: 10px;
}

.footer-colum2 ul {
  font-family: metropolic-regular;
  color: white;
  padding-left: 15px !important;
  list-style: none;
  margin-top: 15px;
}

.footer-colum2 ul li {
  line-height: 30px;
  margin-left: -13px;
}

.footer-colum3 ul {
    font-family: metropolic-regular;
    color: white;
    padding-left: 15px !important;
    list-style: none;
    margin-top: 15px;
  }
  
  .footer-colum3 ul li {
    line-height: 30px;
    margin-left: -13px;
  }

.icons2 {
  font-size: 17px;
  color: #f7ad00;
}

.footer-colum4 p {
  font-family:metropolic-regular;
  color: #ffff;
  font-size: 14px;
}

.contact-content {
  margin-top: 15px;
}

.contact-us1 {
  display: flex;
  justify-content: start;
  margin-top: 20px;
 
}

.contact-us1 p {
    margin-left: 10px;
    margin-bottom: 0!important;
   
  }

.contact-us2  {
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 15px;
   
  }

  .contact-us2 p {
  margin-left: 10px;
  margin-bottom: 0!important;
  text-align: start;
  
   
  }
  
  

.contact-icons {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color:#f7ad00;
}

.contact-us2 span{
    font-size: 13px;
    font-weight: bold;
}


.contact-icons1 {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    color:#f7ad00;
  }

.contact-icon1 {
  margin-bottom: 23px;
  margin-right: 10px;
  color: #f7ad00;
}

.footer-rights{
  width: 100%;
  height: 10vh;
  
}

.footer-rights-content{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-left-content{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.footer-left-content h5 {
  font-family:metropolic-regular ;
  font-size: 14px;
  margin-bottom: 0;
  

}

.footer-left-content span {
  font-family:metropolic-wbold ;

  margin-bottom: 0;
  

}

.footer-rights-border {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.footer-rights-content h5 {
  font-family:metropolic-regular ;
  font-size: 14px;
  margin-bottom: 0;
  

}

.footer-rights-border hr{
  width: 100%;
}

.weborder-button p{
  font-family: metropolic;
}

.weborder-button a {
  color: white !important;
}

.weborder-button a:hover{
  color: #1E4F6D !important;
}

.weborder-button button  {
  color: #fff !important;
}
.weborder-button button:hover {
  color: #1E4F6D !important;
}

.weborder-button button{
  border: none;
  background-color:#1E4F6D ;
  font-family:metropolic ;
  color: #fff;
  border-radius: 100px;
  padding: 5px 15px;
  
}

.weborder-button button:hover{
  border: 1px solid #1E4F6D;
  background-color:#fff;
  font-family:metropolic ;
  color: #1E4F6D;
  border-radius: 100px;
  padding: 5px 15px;
}


@media screen and (max-width: 425px) {
    .footer-colum1 {
        text-align: center;
    }

    .footer-colum2{
        width: 38% !important;
        margin-left: auto;
        margin-right: auto;
    }

    .footer-colum3{
        width: 38% !important;
        margin-left: auto;
        margin-right: auto;
    }

    .footer-colum4 {

        width: 85% !important;
        margin-left: auto;
        margin-right: auto;
    }

    .footer-rights-content{
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .footer-rights-content h5 {
      font-family:metropolic-regular ;
      font-size: 16px;
      margin-bottom: 0;
      margin-top: 15px;
      
    
    }
    .footer-left-content{
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: metropolic-extrabold;
  src: url("../../../../asset/metropolis/Metropolis-Black.otf");
}

@font-face {
  font-family: metropolic-medium;
  src: url("../../../../asset/metropolis/Metropolis-Medium.otf");
}

.aboutus-bg{
  width: 100%;
/* height: 80vh; */
  background-image: url('../../../../asset/aboutbg.png');
  background-size: cover;
  background-position: top;
  margin-top: 120px;


}

/* .aboutus-container{

position: relative;
top: 130px;
} */

.aboutus-img img {
  width: 100%;
}


.aboutus-content h1 {
  font-family: metropolic-extrabold;
  text-transform: uppercase;
  color: #1e4f6d;
}

.aboutus-content span{
  color: #f7ad00;
}

.aboutus-content p {
  font-family: metropolic-medium;
  text-align: justify;
  line-height: 30px;
  font-size: 15px;
}

.readmore-ab {
  font-family: metropolic-bold;
  border: none !important;
  background-color: #f7ad00;
  border-radius: 100px;
  font-size: 15px;
  padding: 8px 17px;
  color: #ffffff;
}

@media screen and (max-width: 768px){
  .aboutus-content h1{
    text-align: center;
  }

  .readmore-ab-button {
    display: flex;
    justify-content: center;
    align-items: center;

  }

}

@media screen and (max-width: 425px){

  .aboutus-content h1 {
    font-family: metropolic-extrabold;
    text-transform: uppercase;
    text-align: center;
    color: #1e4f6d;
  }
}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: metropolic-bold;
  src: url("../../../../asset/metropolis/Metropolis-Black.otf");
}

@font-face {
  font-family: metropolic-medium;
  src: url("../../../../asset/metropolis/Metropolis-Medium.otf");
}

.background {
  /* background-image: linear-gradient(to bottom right, #e8f1ff, #ffffff); */
  /* background-image: url("../../../../asset/bgbanner.png"); */
  /* background-position: center;
  background-size: cover; */
  /* height: 700px; */
  /* width: 100%;
  position: relative; */
}

.slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider h1 {
  position: relative;
  top: 17px;
  font-family: metropolic-bold;
  color: #1e4f6d;
  text-transform: uppercase;
  line-height: 60px;
}

.slider h5 {
  font-family: metropolic-medium;
  color: #202020;
  margin-top: 10px;
}

.slick-next {
  right: 80px !important;
  background-color: rgb(211, 211, 211) !important ;
  z-index: 1000;
}

.slick-prev {
  left: 80px !important;
  background-color: rgb(192, 192, 192) !important ;
  z-index: 1000;
}

.readmore {
  font-family: metropolic-bold;
  border: none !important;
  background-color: #f7ad00;
  border-radius: 100px;
  font-size: 15px;
  padding: 8px 17px;
  color: #ffffff;
}

.slider-button{
display: flex;
justify-content: space-between;
align-items: center;
}

.left{
  border: none;
  position: absolute;
  right: 93%;
  bottom: 60%;
  background-color: transparent;
  color: #f7ad00;
  font-size: 30px;

  
  
}

.right{
  border: none;
  position: absolute;
  left: 93%;
  bottom: 60%;
  background-color: transparent;
  color: #f7ad00;
  font-size: 30px;

 
}

.mobile-size img {
  /* margin-top: 80px; */
  position: relative;
}

.slick-next{
  right: 11px !important;
}


.slick-prev {
  left: 11px !important;
}

@media screen and (max-width: 2560px){
  .left{
    border: none;
    position: absolute;
    right: 93%;
    bottom: 50%;
    background-color: transparent;
    color: #f7ad00;
    font-size: 30px;
  
    
    
  }
  
  .right{
    border: none;
    position: absolute;
    left: 93%;
    bottom: 50%;
    background-color: transparent;
    color: #f7ad00;
    font-size: 30px;
  
   
  }
  
  .mobile-size img {
    /* margin-top: 80px; */
    position: relative;
  }
  
}


@media screen and (max-width: 1440px){
  .left{
    border: none;
    position: absolute;
    right: 88%;
    bottom: 60%;
    background-color: transparent;
    color: #f7ad00;
    font-size: 30px;
  
    
    
  }
  
  .right{
    border: none;
    position: absolute;
    left: 88%;
    bottom: 60%;
    background-color: transparent;
    color: #f7ad00;
    font-size: 30px;
  
   
  }


  .mobile-size img {
    /* margin-top: 80px; */
    position: relative;
  }

}


@media screen and (max-width: 1024px){
  .slider h1 {
    position: relative;
    top: 20px;
    font-family: metropolic-bold;
    font-size: 22px;
    color: #1e4f6d;
    font-size: 30px;
    text-transform: uppercase;
    line-height: 41px;
    
  }

  .readmore {
    font-family: metropolic-bold;
    border: none !important;
    background-color: #f7ad00;
    border-radius: 100px;
    font-size: 14px;
    padding: 4px 14px;
    color: #ffffff;
  }
  .left{
    border: none;
    position: absolute;
    right: 88%;
    bottom: 70%;
    background-color: transparent;
    color: #f7ad00;
    font-size: 30px;
  
    
    
  }
  
  .right{
    border: none;
    position: absolute;
    left: 88%;
    bottom: 70%;
    background-color: transparent;
    color: #f7ad00;
    font-size: 30px;
  
   
  }


  .mobile-size img {
    /* margin-top: 80px; */
    position: relative;
  }



}


@media screen and (max-width: 786px){
  .slider h1 {
    position: relative;
    top: 20px;
    font-family: metropolic-bold;
    font-size: 22px;
    color: #1e4f6d;
    font-size: 25px;
    text-transform: uppercase;
    line-height: 41px;
    
  }

  .left{
    border: none;
    position: absolute;
    right: 88%;
    bottom: 80%;
    background-color: transparent;
    color: #f7ad00;
    font-size: 30px;
  
    
    
  }
  
  .right{
    border: none;
    position: absolute;
    left: 88%;
    bottom: 80%;
    background-color: transparent;
    color: #f7ad00;
    font-size: 30px;
  
   
  }


  .mobile-size img {
    /* margin-top: 80px; */
    position: relative;
  }


}



@media screen and (max-width: 425px) {

   .slider h1 {
   
    font-family: metropolic-bold;
    font-size: 22px;
    color: #1e4f6d;
    text-transform: uppercase;
    line-height: 41px;
    text-align: center;
  }

  /* .slider h5 {  
    font-family: metropolic-medium;
    color: #202020;
    position: relative;
    top: 85px;
    text-align: center;
  } */

  

  /* .readmore{

  position: relative;
  top: 84px;
  left: 35%;
   
    
  }  */
  

  .slider-heading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
  }

  .left{
    border: none;
    position: absolute;
    right: 88%;
    bottom: 70%;
    background-color: transparent;
    color: #f7ad00;
    font-size: 30px;
  
    
    
  }
  
  .right{
    border: none;
    position: absolute;
    left: 88%;
    bottom: 70%;
    background-color: transparent;
    color: #f7ad00;
    font-size: 30px;
  
   
  }

  .mobile-size img {
    margin-top: 80px;
    position: relative;
  }

}
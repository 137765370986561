* {
  box-sizing: border-box;
}

@font-face {
  font-family: metropolic;
  src: url("../../asset/metropolis/Metropolis-Bold.otf");
}





.header{

    
   /* padding-top: 10px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    position: fixed;
    width: 100%;
    z-index: 1000;
    
}

.header-down{

    
    /* padding-top: 10px; */
     display: flex;
     justify-content: space-between;
     align-items: center;
     background-color: white;
     position: fixed;
     width: 100%;
     z-index: 1000;
     box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
     
 }

.logo{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

}

.logo img{
    max-width: 100%;
    max-height: 100%;
}

.nav-list-item{
   width: 0%;
    margin-top: 10px;
}

.nav-list-item ul{
    display: flex;
    list-style: none;
    text-decoration: none;
    justify-content: center;
    align-items: center;
  
}

.nav-list-item ul li{
padding: 20px;
font-size: 15px;
font-family: metropolic;
    
    
}


.menu{
    font-size: 25px;
  
}

/* a{
    color:  #000000;
} */
.modal-footer{
  
    justify-content: space-between !important;
}

.weborder-button p{
    font-family: metropolic;
}

.weborder-button a {
    color: white !important;
}

.weborder-button a:hover{
    color: #1E4F6D !important;
}

.weborder-button button  {
    color: #fff !important;
}
.weborder-button button:hover {
    color: #1E4F6D !important;
}

.weborder-button button{
    border: none;
    background-color:#1E4F6D ;
    font-family:metropolic ;
    color: #fff;
    border-radius: 100px;
    padding: 5px 15px;
    
}

.weborder-button button:hover{
    border: 1px solid #1E4F6D;
    background-color:#fff;
    font-family:metropolic ;
    color: #1E4F6D;
    border-radius: 100px;
    padding: 5px 15px;
}
.slick-next{
    right: 11px !important;
}


.slick-prev {
    left: 11px !important;
}


.dr_content p{

    font-family: metropolic;
    font-size: 16px;

}


@media screen and (max-width: 2560px){

.menu{
    display: none;
}

.logo{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

}


.logo img{
    max-width: 100%;
    max-height: 100%;
}

.nav-list-item{
   width: 70%;
    margin-top: 10px;
}




}

@media screen and (min-width: 1024px)  and (max-width: 1440px){
    .header{

    
        /* padding-top: 10px; */
         display: flex;
         justify-content: space-between;
         align-items: center;
         position: fixed;
         width: 100%;
         z-index: 1000;
         
     }
    
    .logo{
        width: 30%;
        display: flex;
    justify-content: center;
    align-items: center;

    
    }
   
    .nav-list-item{
        width: 80%;
         margin-top: 10px;
     }

     .nav-list-item ul li{
        padding: 10px;
        font-size: 14px;
        font-family: metropolic;
            
            
        }
    
    }


@media screen   and (max-width: 1024px){
    .header{

    
        /* padding-top: 10px; */
         display: flex;
         justify-content: space-between;
         align-items: center;
         position: fixed;
         width: 100%;
         z-index: 1000;
         
     }
    
    .logo{
        width: 30%;
        display: flex;
    justify-content: center;
    align-items: center;

    
    }
   
    .nav-list-item{
        width: 80%;
         margin-top: 10px;
     }

     .nav-list-item ul li{
        padding: 10px;
        font-size: 14px;
        font-family: metropolic;
            
            
        }
    
    }

@media screen and (max-width: 786px){

    .header{

    
        /* padding-top: 10px; */
         display: flex;
         justify-content: space-between;
         align-items: center;
         position: fixed;
         width: 100%;
         z-index: 1000;
         
     }
  
    .menu{

    
        width:8%;
        display: block;
        justify-content: center;
        align-items: center;
       
    
    }
    

    .logo{
        width: 36%;
        display: flex;
    justify-content: center;
    align-items: center;

    
    }

    .nav-list-item{
       display: none;
     }

    
     .header{
         padding-top: 20px;
         padding-bottom: 20px;
     }


    
    }

    @media screen and (max-width: 425px){

        .header{

    
            /* padding-top: 10px; */
             display: flex;
             justify-content: space-between;
             align-items: center;
             position: fixed;
             width: 100%;
             z-index: 1000;
             
         }
         

  .logo img{
      width: 180px;
  }
        .menu{
    
        
            width:13%;
            display: block;
            justify-content: center;
            align-items: center;
           
        
        }
        
    
        .logo{
            width:60%;
            display: flex;
        justify-content: center;
        align-items: center;
    
        
        }
    
        .nav-list-item{
           display: none;
         }
    
        
      
    
        
        }

        @media screen and (max-width: 320px){
            .logo img{
                width: 150px;
            }
        }
    


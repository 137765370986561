* {
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: metropolic-bold;
    src: url("../../../../asset/metropolis/Metropolis-Black.otf");
  }
  
  @font-face {
    font-family: metropolic-medium;
    src: url("../../../../asset/metropolis/Metropolis-Medium.otf");
  }
  

  .aboutus1-bg{
      background-image: url('../../../../asset/aboutus-bg.jpg');
      width: 100%;
      height: 40vh;
      background-repeat: no-repeat;
      background-size: cover;
  }


  .adoutus-heading{
    margin-top: auto;
    margin-bottom: 20px;
/* position: relative;
top: 180px; */

  }

  .adoutus-heading h5{
      font-family:metropolic-bold ;
      font-size: 30px;
      color: #1E4F6D;
  }

  .adoutus-heading p{
      font-family: metropolic-medium;
      margin-bottom: 0 !important;

  }

  .ab-image{
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
  }


  .ab-content{
    margin-top: 50px;
    overflow: hidden;
    max-height: 100%;
    font-family:metropolic-medium ;
    transition: all .5s;
    text-align: justify;
  }
  .ab-content-hide{
    margin-top: 50px;
    overflow: hidden;
    max-height: 310px;
    font-family:metropolic-medium ;
    transition: all .5s;
    text-align: justify;
  }


  .ab-button{
    border: none !important;
    background-color: #F7AD00;
    font-family:metropolic-bold ;
    border-radius: 100px;
    padding: 5px 15px;
    color: #fff;;
  }
  
  @media only screen and (max-width: 768px){

  .adout-cont{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (max-width: 425px){

    .adout-cont{
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
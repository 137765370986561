* {
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: metropolic-Blackbold;
    src: url("../../../../asset/metropolis/Metropolis-Black.otf");
  }
  
  @font-face {
    font-family: metropolic-medium;
    src: url("../../../../asset/metropolis/Metropolis-Medium.otf");
  }
  
  @font-face {
    font-family: metropolic-regular1;
    src: url("../../../../asset/metropolis/Metropolis-Regular.otf");
  }

  .contactdetails-section{
      margin-top: 135px;
  }

  .contactdetails-heading h1 {
      font-family:metropolic-medium ;
      font-size: 30px;
      color: #0F3851;
      text-align: center;
  }

  .contactdetails-heading span {
      font-family:metropolic-Blackbold ;
      color: #F7AD00;
  }

  .cont-phone :hover{
    width: 100px;
      height: 100px;
      background-color:#F7AD00 ;
     
      border-radius: 100px;
      transition: all .2s;
      


  }

  .cont-phone{
    width: 100px;
    height: 100px;
    background-color: #0F3851;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    

    font-size: 40px;
  }

  .phone-section{
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
  }

  .cont-phone-content{
      font-family:metropolic-regular1 ;
      text-align: center;
  }
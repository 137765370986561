* {
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #d6d6d6;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #f7ad00;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e4f6d;
}

@font-face {
  font-family: metropolic-extrabold;
  src: url("../src/asset/metropolis/Metropolis-Black.otf");
}

@font-face {
  font-family: metropolic-medium;
  src: url("../src/asset/metropolis/Metropolis-Medium.otf");
}

@font-face {
  font-family: metropolic-semibold;
  src: url("../src/asset/metropolis/Metropolis-SemiBold.otf");
}

@font-face {
  font-family: metropolic-reg;
  src: url("../src/asset/metropolis/Metropolis-Regular.otf");
}

a {
  color: #4d4d4d !important;
}

.call-button-align {
  display:block;
  justify-content: end;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.callbutton-icon {
  font-size: 55px;
  color: #ffffff;
  transition: all 0.5s;
}
.callbt-para p {
  margin: 0 10px !important;
  display: block;
  font-family: metropolic-extrabold;
  font-size: 14px;
  color: #4d4d4d;
}

.chat-box-container {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  /* top: 45%; */
  right: 10px;
  bottom: 120px;
  background-color: #ffffff;
  z-index: 1000;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}
.callbutton-open {
  display: block;
}

.callbutton-close {
  display: none;
}

.callbutton {
  display: inline-block;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  /* top: 88%; */
  right: 15px;
  width: 64px;
  padding: 5px 5px;
  background-color: #0d9940;
  z-index: 1000;
  border-radius: 100px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
  cursor: pointer;
}

.chat-box-header {
  display: block;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  top: 45%;
  right: 15px;
  width: 300px;
  height: 60px;
  padding: 5px 5px;
  background-color: #3a5664;
  z-index: 1000;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.chat-box-header-inside {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.chat-box-header-inside h3 {
  margin-bottom: 0 !important;
  margin-left: 10px;
  margin-top: 14px;
  font-family: metropolic-semibold;
  font-size: 17px;
  color: white;
}

.chat-box-header2 {
  display: block;
  justify-content: center;
  align-items: center;
  /* position: fixed; */
  /* top: 54%; */
  right: 15px;
  /* width: 300px; */
  height: 60px;
  padding: 5px 5px;
  border-bottom: 1px solid rgb(219, 219, 219);
  z-index: 1000;
}

.chat-box-header2:hover {
  background-color: rgb(231, 231, 231);
  transition: all 0.5s;
  cursor: pointer;
}

.chat-box-inside2 {
  display: flex;
  justify-content: start;
  align-items: center;
}
.chat-box-inside-icon2 {
  font-size: 20px;
  color: #cfcfcf;
  margin-right: 10px;
  margin-top: 10px;
}

.chat-box-inside-icon {
  font-size: 48px;
  color: #cfcfcf;
}

.chat-box-inside2 h3 {
  margin-bottom: 0 !important;
  margin-left: 10px;
  margin-top: 0px;
  font-family: metropolic-semibold;
  font-size: 16px;
  color: #4d4d4d;
}

.chat-box-inside2 p {
  margin-bottom: 0 !important;
  margin-left: 10px;
  font-family: metropolic-reg;
  font-size: 10px;
  color: #a1a1a1;
}

.chat-box-header-icon {
  font-size: 48px;
  color: #cfcfcf;
  margin-left: 10px;
}


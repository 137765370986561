* {
  box-sizing: border-box;
}

@font-face {
  font-family: metropolic-Blackbold;
  src: url("../../../../asset/metropolis/Metropolis-Black.otf");
}

@font-face {
  font-family: metropolic-medium;
  src: url("../../../../asset/metropolis/Metropolis-Medium.otf");
}

@font-face {
  font-family: metropolic-regular1;
  src: url("../../../../asset/metropolis/Metropolis-Regular.otf");
}

.contact-form-heading h1 {
  font-family: metropolic-Blackbold;
  font-size: 28px;
  text-align: center;
  color: #0f3851;
  margin-top: 10px;
}

.contact-form-heading span {
  color: #f7ad00;
}

.contact-form-heading p {
  font-family: metropolic-medium;
  text-align: center;
  margin-bottom: 0;
}

.from-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contact-form {
  /* background-color: red; */
  padding: 0 10px;
}

.contact-button button {
  font-family: metropolic-Blackbold;
  font-size: 18px;
  text-transform: uppercase;
  background-color: #f7ad00;
  color: #ffffff;
  width: 100%;
  padding: 6px;
  border-radius: 5px;
  border: none;
}

.contact-form-image img {
  width: 80%;
  display: block;
  margin: auto;
}

* {
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: metropolic-nbold;
    src: url("../../../../asset/metropolis/Metropolis-Bold.otf");
  }
  
  @font-face {
    font-family: metropolic-regular2;
    src: url("../../../../asset/metropolis/Metropolis-Regular.otf");
  }
  



.bg-section{
    margin-top: 120px;
    width: 100%;
   padding-top: 90px;
   padding-bottom: 80px;
    background-image: url('../../../../asset/ourservicesbg.png');
    background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
    
}

.service-container{
    text-align: center;
}

.service-container h5{
    font-family:metropolic-nbold ;
    color: #ffffff;
    font-size: 16px;
}

.service-container p{
    font-family:metropolic-regular2 ;
    font-size: 13px;
    line-height: 25px;
    color: #ffffff;
}

.service-content{
    width: 100%;
   
    margin-top: auto;
    margin-bottom: auto;
    
    
}

.services-circle1{
    display: inline-block;
    border-radius: 100px;
    padding: 20px;
background-color: #F7AD00;
margin-bottom: 20px;

    
   

}

.services-circle2{
    display: inline-block;
    border-radius: 100px;
    padding: 20px;
background-color: #1E4F6D;
margin-bottom: 20px;

    
   

}

.services-circle3{
    display: inline-block;
    border-radius: 100px;
    padding: 20px;
background-color: #F7AD00;
margin-bottom: 20px;

    
   

}

.services-circle4{
    display: inline-block;
    border-radius: 100px;
    padding: 20px;
background-color: #1E4F6D;
margin-bottom: 20px;

}

.services-heading{
display: flex;
justify-content: center;
margin-bottom: 40px;


}

.services-heading h2{
    font-family: metropolic-nbold ;
    color: #ffffff;
}


.services-heading span{
    font-family: metropolic-nbold ;
    color: #F7AD00;
}


* {
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: metropolic-bold;
    src: url("../../../asset/metropolis/Metropolis-Black.otf");
  }
  
  @font-face {
    font-family: metropolic-medium;
    src: url("../../../asset/metropolis/Metropolis-Medium.otf");
  }
  
  @font-face {
    font-family: metropolic-regular1;
    src: url("../../../asset/metropolis/Metropolis-Regular.otf");
  }


  .product-bg{
      background-image: url('../../../asset/product-bg.png');
      width: 100%;
      height: 40vh;
      background-repeat: no-repeat;
      background-size: cover;
  }


  .product-heading {
    
    
      margin-top: auto;
      margin-bottom: 80px;
      text-align: center;
  }

  .product-heading h1{
      font-family:metropolic-bold ;
      font-size: 30px;
      color: #0F3851;
  }

  .product-heading p{
      font-family: metropolic-regular1;
  }

  .product-list{
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    margin-bottom: 15px;
    background-color: #f8f8f8;
    border-bottom: 1px solid rgb(236, 236, 236);
  }


  .product-list p{
    font-family:metropolic-medium;
    margin-bottom: 0;
    padding: 15px 0px;
    
  }
  

  .product-search{
    text-align: right;


    
  }

  .product-search-icon{
    position: relative;
    left: 31px;
 bottom: 1.5px;
    font-size: 28px;
    color: #0F3851;
  }

  .product-search input{
    border: 1px solid #0F3851;
    background-color: rgb(241, 241, 241);
    border-radius: 100px;
    
    height: 35px;
    padding-left: 35px;
    
  }
  
  .pagination{
    display: flex;
    justify-content: end;
    align-items:center ;
  }

  .page-dropdown label{
    margin-right: 10px;
  }

  .page-dropdown select {
    border: none;
    background-color: #ebebeb;
    padding:5px 8px;
    border-radius: 4px;
  }


  @media only screen and (max-width: 425px) {

    .product-search{

    }
    
    .pagination{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items:center ;
    }

  }
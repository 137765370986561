* {
  box-sizing: border-box;
}

@font-face {
  font-family: metropolic-Blackbold;
  src: url("../../../asset/metropolis/Metropolis-Black.otf");
}

@font-face {
  font-family: metropolic-medium;
  src: url("../../../asset/metropolis/Metropolis-Medium.otf");
}

@font-face {
  font-family: metropolic-regular1;
  src: url("../../../asset/metropolis/Metropolis-Regular.otf");
}

.career-bg {
  background-image: url("../../../asset/career-bg.png");
  width: 100%;
  height: 115vh;
  background-repeat: no-repeat;
  background-size: cover;
}

.career-bg-align {
  display: "flex";
  height: 115vh;
  justify-content: space-around;
  align-items: center;
}

.career-form {
  background-color: rgb(33, 90, 103, 0.5);
  border-radius: 10px;

  text-align: left;
  width: 100%;
  padding: 25px 25px !important;
}

.f-lable {
  color: #fff;
  font-family: metropolic-medium;
  font-size: 15px;
}

.career-form h1 {
  font-family: metropolic-Blackbold;
  font-size: 23px;
  color: #fff;
}

.form-button button {
  width: 100%;
  padding: 7px;
  border-radius: 5px;
  margin-top: 15px;
  font-family: metropolic-Blackbold;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  border: none;
  background-color: #f7ad00;
}

.career-breadcrums h1 {
  font-family: metropolic-Blackbold;
  font-size: 30px;
  margin-top: 10px;
  color: #0f3851;
}

.career-breadcrums p {
  font-size: metropolic-medium;
  font-size: 16px;
  margin-bottom: 0 !important;
}



@media screen and (max-width: 1024px) {
  .career-bg {
    background-image: url("../../../asset/career-bg.png");
    width: 100%;
    height: 110vh;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .career-bg-align {
    display: "flex";
    height: 110vh;
    justify-content: end;
    align-items: center;
  }

  .career-breadcrums {
    margin-top: 100px !important;
  }

  .career-breadcrums h1 {
    font-family: metropolic-Blackbold;
    font-size: 25px;
    margin-top: 10px;
  }

  .career-breadcrums p {
    font-family: metropolic-medium;
    font-size: 16px;
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 768px) {
  .career-bg {
    background-image: url("../../../asset/career-bg.png");
    width: 100%;
    height: 130vh;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .career-bg-align {
    display: "flex";
    height: 130vh;
    justify-content: end;
    align-items: center;
    margin: 0;
  }

  .career-breadcrums {
    margin-top: 100px !important;
  }

  .career-breadcrums h1 {
    font-family: metropolic-Blackbold;
    font-size: 30px;
    margin-top: 10px;
    text-align: center;
  }

  .career-breadcrums p {
    font-family: metropolic-medium;
    font-size: 16px;
    margin-bottom: 0 !important;
    text-align: center;
  }
}

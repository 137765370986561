* {
  box-sizing: border-box;
}

@font-face {
  font-family: metropolic-bold;
  src: url("../../../../asset/metropolis/Metropolis-Black.otf");
}

@font-face {
  font-family: metropolic-medium;
  src: url("../../../../asset/metropolis/Metropolis-Medium.otf");
}

@font-face {
  font-family: metropolic-regular1;
  src: url("../../../../asset/metropolis/Metropolis-Regular.otf");
}

.about-director {
  background-image: url("../../../../asset/aboutbg.png");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  margin-top: 50px;
  transition: all .5s;
}

.director-heading h1 {
  font-family: metropolic-medium;
  font-size: 30px;
  color: #1e4f6d;
}

.director-heading span {
  font-family: metropolic-bold;
  color: #f7ad00;
}



.director-img{
  width: 50%;
  margin: 0 auto;
  border: 1px solid #e8f1ff;
  padding: 10px;
  box-shadow: #e8f1ff 0px 7px 29px 0px;
  border-radius: 10px;
  background-color: #ffff;
  cursor: pointer;


}

.director-img img{
  width: 100%;
}


.director-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.director-name2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.director-name h3 {
  font-family: metropolic-bold;
  margin-bottom: 0 !important;
  margin-top: 17px;
  font-size: 17px;
  color: #1e4f6d;
}

.director-name p {
  margin-bottom: 0 !important;
  font-family: metropolic-regular1;
  font-size: 15px;
}

.director-icon {
  font-size: 28px;
  color: #f7ad00;
}

.director-icon2 {
  font-size: 28px;
  color: #f7ad00;
}

.director-name2 h3 {
  font-family: metropolic-bold;
  margin-bottom: 0 !important;
  margin-top: 15px;
  font-size: 17px;
  color: #1e4f6d;
}

.director-name2 p {
  margin-bottom: 0 !important;
  font-family: metropolic-regular1;
  font-size: 15px;
}

.model-box{
  text-align: justify;
  font-family: metropolic-regular1;
  font-size: 13px;
}



.model-box-image img{
  width: 100%;
  padding: 20px;
}
.model-box-image img{
 padding-left: 15px;
 padding-top: 15px;
 padding-right: 15px;
}

.model-close-icon{
 
font-size: 50px;
color: #f7ad00;
padding-top: 15px;
padding-bottom: 0 !important;
padding-right: 10px;
}


.director-card{
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width: 768px) {

  .director-img{
    width: 100%;
   
  
  
  }
  
}
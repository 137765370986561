* {
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: metropolic-Blackbold;
    src: url("../../../asset/metropolis/Metropolis-Black.otf");
  }
  
  @font-face {
    font-family: metropolic-medium;
    src: url("../../../asset/metropolis/Metropolis-Medium.otf");
  }
  
  @font-face {
    font-family: metropolic-regular1;
    src: url("../../../asset/metropolis/Metropolis-Regular.otf");
  }
  

  .contact-bg{
      background-image: url('../../../asset/contact-bg.png');
      width: 100%;
      height: 40vh;
      background-repeat: no-repeat;
      background-size: cover;
  }

  .contact-heading{
      margin-top: auto;
      margin-bottom: 20px;
  }

  .contact-heading h1{
      font-family:metropolic-Blackbold ;
      font-size: 30px;
      color: #0F3851;
      margin-top: 5px;


  }

  .contact-heading p {
      font-family:metropolic-medium ;
      margin-bottom: 0;
  }

 